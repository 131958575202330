import { useMemo, useEffect, useContext, useRef } from 'react'
import { useTable, useFilters, useSortBy, usePagination } from 'react-table'
import { makeStyles } from '@material-ui/core'
import TableHeader from './TableHeader'
import TableActions from './TableActions'
import ColumnFilter from './ColumnFilter'
import { fuzzyTextFilterFn } from '../helpers/fuzzy-text-filter'
import { filterGreaterThan } from '../helpers/filter-greater-than'
import { stateReducer } from '../reducers/stateReducer'
import { FilterReactTableContext } from 'contexts/filterReactTableContext'
import styles from '../styles/reactTableStyles'

const useStyles = makeStyles(styles)

fuzzyTextFilterFn.autoRemove = (val) => !val

const ReactTablePagination = ({
	columns,
	data,
	displayButtons = false,
	keepHeader = true,
	fetchData,
	nextPage: next,
	prevPage: prev,
	totalPages,
}) => {
	const classes = useStyles()
	const reactTableRootRef = useRef()
	const tableRef = useRef()
	const theadRef = useRef()
	const firstTrRef = useRef()

	const { setFilteredColumns, filteredRows, setfilteredRows } = useContext(FilterReactTableContext)

	const filterTypes = useMemo(
		() => ({
			fuzzyText: fuzzyTextFilterFn,
			text: (rows, id, filterValue) => {
				return rows.filter((row) => {
					const rowValue = row.values[id]
					return rowValue !== undefined
						? String(rowValue).toLowerCase().startsWith(String(filterValue).toLowerCase())
						: true
				})
			},
		}),
		[],
	)
	const defaultColumn = useMemo(() => ({ Filter: ColumnFilter }), [])
	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		page,
		prepareRow,
		nextPage,
		pageOptions,
		previousPage,
		canPreviousPage,
		canNextPage,
		setPageSize,
		gotoPage,
		rows,
		state: { customPage, pageIndex, pageSize },
		dispatch,
	} = useTable(
		{
			columns,
			data,
			defaultColumn,
			filterTypes,
			initialState: { pageSize: 100, pageIndex: 0, customPage: '' },
			pageSize: 100,
			pageCount: totalPages,
			manualPagination: true,
			stateReducer: (newState, action, prevState) => stateReducer(newState, action, prevState),
			autoResetPage: false,
			autoResetFilters: false,
		},
		useFilters,
		useSortBy,
		usePagination,
	)

	useEffect(() => {
		if (customPage !== '') {
			fetchData(customPage)
		}
	}, [customPage])
	useEffect(() => {
		const listener = () => {
			const tableCurrentWidth = reactTableRootRef.current.offsetWidth
			const firstTrCurrentWidth = firstTrRef.current.offsetWidth
			if (document.getElementById('thead')?.getBoundingClientRect()?.y < 0) {
				theadRef.current.style.position = 'fixed'
				theadRef.current.style.overflowX = 'auto'
				theadRef.current.style.top = '0px'
				firstTrRef.current.style.width = `${firstTrCurrentWidth}px`
				theadRef.current.style.width = `${tableCurrentWidth}px`
				theadRef.current.scrollLeft = reactTableRootRef.current.scrollLeft
			}
			if (
				(theadRef.current.style.position = 'fixed' && document.getElementById('tbody')?.getBoundingClientRect()?.y > 0)
			) {
				theadRef.current.style.overflowX = ''
				theadRef.current.style.position = ''
				firstTrRef.current.style.width = ''
				theadRef.current.style.width = ''
			}
		}

		keepHeader && document.getElementById('mainPanel').addEventListener('scroll', listener)
		return () => {
			keepHeader && document.getElementById('mainPanel').removeEventListener('scroll', listener)
		}
	}, [tableRef])
	useEffect(() => {
		if (rows.length !== filteredRows.length) {
			const array = rows.map((row) => {
				return {
					...row.original,
				}
			})
			setfilteredRows(array)
			const data = columns?.filter((column) => column.Header !== '' && column.Header !== 'Acciones')
			setFilteredColumns(data)
		}
	}, [rows])

	const customPrevManage = () => {
		dispatch({ type: 'setToken', payload: prev })
		previousPage()
	}
	const customNextManage = () => {
		dispatch({ type: 'setToken', payload: next })
		nextPage()
	}
	const customGoToPage = (event) => {
		dispatch({ type: 'setToken', payload: `?page=${event.target.value}&limit=${pageSize}` })
		gotoPage(event.target.value)
	}
	const customPageSize = (event) => {
		dispatch({ type: 'setToken', payload: `?page=0&limit=${event.target.value}` })
		gotoPage(0)
		setPageSize(event.target.value)
	}

	const columnSize = []

	return (
		<div
			ref={reactTableRootRef}
			id='react-table-root'
			style={{ position: 'unset' }}
			onScroll={(e) => {
				theadRef.current.scrollLeft = e.target.scrollLeft
			}}
			className={'ReactTable -striped -highlight ' + classes.overflowX}
		>
			{!displayButtons && (
				<div className={'pagination-top' + ' ' + classes.pagPosition}>
					<TableHeader
						pageOptions={pageOptions}
						pageIndex={pageIndex}
						customGoToPage={customGoToPage}
						pageSize={pageSize}
						customPageSize={customPageSize}
						customPrevManage={customPrevManage}
						canPreviousPage={canPreviousPage}
						customNextManage={customNextManage}
						canNextPage={canNextPage}
						displayButtons={displayButtons}
					/>
				</div>
			)}
			<table
				ref={tableRef}
				id={'rt-table'}
				{...getTableProps()}
				className={'rt-table ' + classes.tableStyles}
				style={{ display: 'table', width: '100%' }}
			>
				<TableActions
					theadRef={theadRef}
					headerGroups={headerGroups}
					getTableBodyProps={getTableBodyProps}
					page={page}
					prepareRow={prepareRow}
					columnSize={columnSize}
					firstTrRef={firstTrRef}
				/>
			</table>
			<div className='pagination-bottom'></div>
		</div>
	)
}

filterGreaterThan.autoRemove = (val) => typeof val !== 'number'

export default ReactTablePagination
