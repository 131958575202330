import { useState } from 'react'
import { NavLink } from 'react-router-dom'
import cx from 'classnames'
import { ListItem, ListItemText, Collapse, Icon, makeStyles } from '@material-ui/core'
import { List } from '@mui/material'
import {
	AccountBox,
	Alarm,
	Analytics,
	Assessment,
	ContentPasteGo,
	ContentPasteSearch,
	CoPresent,
	EuroSymbol,
	FolderCopy,
	HowToReg,
	List as ListIcon,
	ListAlt,
	Login,
	NotificationAdd,
	Notifications,
	People,
	Percent,
	PersonAdd,
	PointOfSale,
	PriceCheck,
	Report,
	SafetyDivider,
	Source,
	SportsScore,
	SupervisedUserCircle,
} from '@mui/icons-material'
import LogoutOption from './LogoutOption'
import InvestorOptions from './InvestorOptions'
import styles from '../styles/sidebarStyle'
import { DeveloperBoard, Info } from '@material-ui/icons'
import ROLES from '../../../config/roles/roles'

const useStyles = makeStyles(styles)

const SidebarOptions = ({ userInfo, isMini }) => {
	const classes = useStyles()

	const [adminCollapse, setAdminCollapse] = useState(false)
	const [administrateCollapse, setAdministrateCollapse] = useState(false)
	const [PDLadministrateCollapse, setPDLAdministrateCollapse] = useState(false)
	const [developersCollapse, setDevelopersCollapse] = useState(false)
	const [investorCollapse, setInvestorCollapse] = useState(false)
	const [investmentCollapse, setInvestmentCollapse] = useState(false)
	const [stadisticCollapse, setStadisticCollapse] = useState(false)
	const [prescriberCollapse, setPrescriberCollapse] = useState(false)
	const [preInvestorCollapse, setPreInvestorCollapse] = useState(false)
	const [preRegisterCollapse, setPreRegisterCollapse] = useState(false)
	const [notificationCollapse, setNotificationCollapse] = useState(false)
	const [ownersCollapse, setOwnersCollapse] = useState(false)

	const itemText = cx({
		[classes.itemText]: true,
		[classes.itemTextMini]: isMini,
	})
	const collapseItemText = cx({
		[classes.collapseItemText]: true,
		[classes.collapseItemTextMini]: isMini,
	})
	const collapseItemLink = cx({
		[classes.itemLink]: true,
		[classes.collapseItemLink]: true,
	})

	const userIsAdmin = [ROLES.ADMIN_ROLE, ROLES.SUPER_ROLE].includes(userInfo.role)

	return (
		<List className={classes.list}>
			{/* InvestorOptions */}
			{userInfo.role === 'investor' && (
				<InvestorOptions
					userInfo={userInfo}
					itemText={itemText}
					collapseItemText={collapseItemText}
					setAdminCollapse={setAdminCollapse}
					adminCollapse={adminCollapse}
					preRegisterCollapse={preRegisterCollapse}
					setPreRegisterCollapse={setPreRegisterCollapse}
				/>
			)}
			{/* Administrar */}
			{(userIsAdmin ||
				userInfo.isManagement ||
				userInfo.isContabilidad ||
				userInfo.isMarketing ||
				userInfo.isInvestorRelations) && (
				<ListItem className={classes.collapseItem}>
					<NavLink
						to={'#!'}
						className={classes.itemLink + ' ' + classes.collapseItemLink}
						onClick={(e) => {
							e.preventDefault()
							setAdministrateCollapse(!administrateCollapse)
						}}
					>
						<span className={classes.collapseItemMini}>
							<FolderCopy />
						</span>
						<ListItemText
							primary={'Administrar'}
							secondary={
								<b
									className={
										classes.caret + ' ' + classes.userCaret + ' ' + (administrateCollapse ? classes.caretActive : '')
									}
								/>
							}
							disableTypography={true}
							className={collapseItemText}
						/>
					</NavLink>
					<Collapse in={administrateCollapse} unmountOnExit>
						<List className={classes.list + ' ' + classes.collapseList}>
							{/* Listado de Proyectos */}
							{(userIsAdmin || userInfo.isManagement || userInfo.isContabilidad || userInfo.isMarketing) && (
								<ListItem className={collapseItemText}>
									<NavLink to={'/admin/list-projects'} className={classes.itemLink + ' ' + classes.collapseItemLink}>
										<span className={classes.collapseItemMini}>
											<ListIcon />
										</span>
										<ListItemText
											primary='Listado de Proyectos'
											disableTypography={true}
											className={collapseItemText}
										/>
									</NavLink>
								</ListItem>
							)}
							{/* Co-Inversiones */}
							{(userIsAdmin || userInfo.isManagement || userInfo.isContabilidad || userInfo.isInvestorRelations) && (
								<ListItem className={collapseItemText}>
									<NavLink
										to={'#!'}
										className={classes.itemLink + ' ' + classes.collapseItemLink}
										onClick={(e) => {
											e.preventDefault()
											setInvestmentCollapse(!investmentCollapse)
										}}
									>
										<span className={classes.collapseItemMini}>
											<EuroSymbol />
										</span>
										<ListItemText
											primary={'Co-Inversiones'}
											secondary={
												<b
													className={
														classes.caret +
														' ' +
														classes.userCaret +
														' ' +
														(investmentCollapse ? classes.caretActive : '')
													}
												/>
											}
											disableTypography={true}
											className={collapseItemText}
										/>
									</NavLink>
									<Collapse in={investmentCollapse} unmountOnExit>
										<List className={classes.list + ' ' + classes.collapseList}>
											{/* Realizar Co-Inversión */}
											<ListItem className={collapseItemText}>
												<NavLink to={'/admin/projects'} className={classes.itemLink + ' ' + classes.collapseItemLink}>
													<span className={classes.collapseItemMini}>
														<EuroSymbol />
													</span>
													<ListItemText
														primary='Realizar Inversión'
														disableTypography={true}
														className={collapseItemText}
													/>
												</NavLink>
											</ListItem>
											{/* Listado de Co-Inversiones */}
											<ListItem className={collapseItemText}>
												<NavLink
													to={'/admin/investment-list'}
													className={classes.itemLink + ' ' + classes.collapseItemLink}
												>
													<span className={classes.collapseItemMini}>
														<ListIcon />
													</span>
													<ListItemText
														primary='Listado de Co-Inversiones'
														disableTypography={true}
														className={collapseItemText}
													/>
												</NavLink>
											</ListItem>
											{/* Liquidar Co-Inversiones */}
											{(userIsAdmin || userInfo.isContabilidad || userInfo.isManagement) && (
												<ListItem className={collapseItemText}>
													<NavLink
														to={'/admin/liquidate-project'}
														className={classes.itemLink + ' ' + classes.collapseItemLink}
													>
														<span className={classes.collapseItemMini}>
															<SportsScore />
														</span>
														<ListItemText
															primary='Liquidar Co-Inversiones'
															disableTypography={true}
															className={collapseItemText}
														/>
													</NavLink>
												</ListItem>
											)}
											{/* Co-Inversiones Liquidadas */}
											<ListItem className={collapseItemText}>
												<NavLink
													to={'/admin/liquidated-investment-list'}
													className={classes.itemLink + ' ' + classes.collapseItemLink}
												>
													<span className={classes.collapseItemMini}>
														<PriceCheck />
													</span>
													<ListItemText
														primary='Co-Inversiones Liquidadas'
														disableTypography={true}
														className={collapseItemText}
													/>
												</NavLink>
											</ListItem>
											{/* Co-Inversión por Inversor */}
											<ListItem className={collapseItemText}>
												<NavLink
													to={'/admin/investments-by-investor'}
													className={classes.itemLink + ' ' + classes.collapseItemLink}
												>
													<span className={classes.collapseItemMini}>
														<ListAlt />
													</span>
													<ListItemText
														primary='Co-Inversión por Inversor'
														disableTypography={true}
														className={collapseItemText}
													/>
												</NavLink>
											</ListItem>
										</List>
									</Collapse>
								</ListItem>
							)}
							{/* PDL */}
							{(userIsAdmin || userInfo.isManagement || userInfo.isContabilidad || userInfo.isInvestorRelations) && (
								<ListItem className={collapseItemText}>
									<NavLink
										to={'#!'}
										className={classes.itemLink + ' ' + classes.collapseItemLink}
										onClick={(e) => {
											e.preventDefault()
											setPDLAdministrateCollapse(!PDLadministrateCollapse)
										}}
									>
										<span className={classes.collapseItemMini}>
											<Source />
										</span>
										<ListItemText
											primary={'PDL'}
											secondary={
												<b
													className={
														classes.caret +
														' ' +
														classes.userCaret +
														' ' +
														(PDLadministrateCollapse ? classes.caretActive : '')
													}
												/>
											}
											disableTypography={true}
											className={collapseItemText}
										/>
									</NavLink>
									<Collapse in={PDLadministrateCollapse} unmountOnExit>
										<List className={classes.list + ' ' + classes.collapseList}>
											{/* Registrar PDL */}
											<ListItem className={collapseItemText}>
												<NavLink to={'/admin/pdls'} className={collapseItemLink}>
													<Icon className={classes.itemIcon}>{<ContentPasteGo />}</Icon>
													<ListItemText
														primary={'Registrar PDL'}
														disableTypography={true}
														className={cx({ [itemText]: true }, { [collapseItemText]: false })}
													/>
												</NavLink>
											</ListItem>
											{/* Listado de PDL */}
											<ListItem className={collapseItemText}>
												<NavLink to={'/admin/pdl-list'} className={collapseItemLink}>
													<Icon className={classes.itemIcon}>{<ListIcon />}</Icon>
													<ListItemText
														primary={'Listado de PDL'}
														disableTypography={true}
														className={cx({ [itemText]: true }, { [collapseItemText]: false })}
													/>
												</NavLink>
											</ListItem>
											{/* Liquidar PDL */}
											<ListItem className={collapseItemText}>
												<NavLink to={'/admin/pdl-liquidate'} className={collapseItemLink}>
													<Icon className={classes.itemIcon}>{<PointOfSale />}</Icon>
													<ListItemText
														primary={'Liquidar PDL'}
														disableTypography={true}
														className={cx({ [itemText]: true }, { [collapseItemText]: false })}
													/>
												</NavLink>
											</ListItem>
											{/* PDL liquidados */}
											<ListItem className={collapseItemText}>
												<NavLink to={'/admin/pdl-liquidated'} className={collapseItemLink}>
													<Icon className={classes.itemIcon}>{<ListIcon />}</Icon>
													<ListItemText
														primary={'PDL Liquidados'}
														disableTypography={true}
														className={cx({ [itemText]: true }, { [collapseItemText]: false })}
													/>
												</NavLink>
											</ListItem>
										</List>
									</Collapse>
								</ListItem>
							)}
							{/* Prescriptores */}
							{(userIsAdmin || userInfo.isManagement || userInfo.isInvestorRelations) && (
								<ListItem className={collapseItemText}>
									<NavLink
										to={'#!'}
										className={classes.itemLink + ' ' + classes.collapseItemLink}
										onClick={(e) => {
											e.preventDefault()
											setPrescriberCollapse(!prescriberCollapse)
										}}
									>
										<span className={classes.collapseItemMini}>
											<SafetyDivider />
										</span>
										<ListItemText
											primary={'Prescriptores'}
											secondary={
												<b
													className={
														classes.caret +
														' ' +
														classes.userCaret +
														' ' +
														(prescriberCollapse ? classes.caretActive : '')
													}
												/>
											}
											disableTypography={true}
											className={collapseItemText}
										/>
									</NavLink>
									<Collapse in={prescriberCollapse} unmountOnExit>
										<List className={classes.list + ' ' + classes.collapseList}>
											<ListItem className={collapseItemText}>
												<NavLink
													to={'/admin/investor-prescriber-list'}
													className={classes.itemLink + ' ' + classes.collapseItemLink}
												>
													<span className={classes.collapseItemMini}>
														<CoPresent />
													</span>
													<ListItemText
														primary='Listado de Prescriptores'
														disableTypography={true}
														className={collapseItemText}
													/>
												</NavLink>
											</ListItem>
										</List>
									</Collapse>
								</ListItem>
							)}
							{/* Inversores */}
							{(userIsAdmin || userInfo.isManagement || userInfo.isContabilidad || userInfo.isInvestorRelations) && (
								<ListItem className={collapseItemText}>
									<NavLink
										to={'#!'}
										className={classes.itemLink + ' ' + classes.collapseItemLink}
										onClick={(e) => {
											e.preventDefault()
											setInvestorCollapse(!investorCollapse)
										}}
									>
										<span className={classes.collapseItemMini}>
											<People />
										</span>
										<ListItemText
											primary={'Inversores'}
											secondary={
												<b
													className={
														classes.caret +
														' ' +
														classes.userCaret +
														' ' +
														(investorCollapse ? classes.caretActive : '')
													}
												/>
											}
											disableTypography={true}
											className={collapseItemText}
										/>
									</NavLink>
									<Collapse in={investorCollapse} unmountOnExit>
										<List className={classes.list + ' ' + classes.collapseList}>
											{/* Registrar Inversor */}
											{(userIsAdmin ||
												userInfo.isManagement ||
												userInfo.isContabilidad ||
												userInfo.isInvestorRelations) && (
												<ListItem className={collapseItemText}>
													<NavLink
														to={'/admin/investor-register-user'}
														className={classes.itemLink + ' ' + classes.collapseItemLink}
													>
														<span className={classes.collapseItemMini}>
															<PersonAdd />
														</span>
														<ListItemText
															primary='Registrar Inversor'
															disableTypography={true}
															className={collapseItemText}
														/>
													</NavLink>
												</ListItem>
											)}
											{/* Listado de Inversores */}
											{(userIsAdmin ||
												userInfo.isManagement ||
												userInfo.isContabilidad ||
												userInfo.isInvestorRelations) && (
												<ListItem className={collapseItemText}>
													<NavLink
														to={'/admin/investor-list-users'}
														className={classes.itemLink + ' ' + classes.collapseItemLink}
													>
														<span className={classes.collapseItemMini}>
															<ListIcon />
														</span>
														<ListItemText
															primary='Listado de Inversores'
															disableTypography={true}
															className={collapseItemText}
														/>
													</NavLink>
												</ListItem>
											)}
											{/* Listado de Perfiles */}
											{(userIsAdmin ||
												userInfo.isManagement ||
												userInfo.isContabilidad ||
												userInfo.isInvestorRelations) && (
												<ListItem className={collapseItemText}>
													<NavLink
														to={'/admin/investor-list-profiles'}
														className={classes.itemLink + ' ' + classes.collapseItemLink}
													>
														<span className={classes.collapseItemMini}>
															<AccountBox />
														</span>
														<ListItemText
															primary='Listado de Perfiles'
															disableTypography={true}
															className={collapseItemText}
														/>
													</NavLink>
												</ListItem>
											)}
											{/* Retenciones */}
											{(userIsAdmin ||
												userInfo.isManagement ||
												userInfo.isContabilidad ||
												userInfo.isInvestorRelations) && (
												<ListItem className={collapseItemText}>
													<NavLink
														to={'/admin/retentions'}
														className={classes.itemLink + ' ' + classes.collapseItemLink}
													>
														<span className={classes.collapseItemMini}>
															<Percent />
														</span>
														<ListItemText primary='Retenciones' disableTypography={true} className={collapseItemText} />
													</NavLink>
												</ListItem>
											)}
											{/* Pre-Registro Inversores */}
											{(userIsAdmin || userInfo.isManagement || userInfo.isInvestorRelations) && (
												<ListItem className={collapseItemText}>
													<NavLink
														to={'#!'}
														className={classes.itemLink + ' ' + classes.collapseItemLink}
														onClick={(e) => {
															e.preventDefault()
															setPreInvestorCollapse(!preInvestorCollapse)
														}}
													>
														<span className={classes.collapseItemMini}>
															<PersonAdd />
														</span>
														<ListItemText
															primary={'PreRegistro Inversores'}
															secondary={
																<b
																	className={
																		classes.caret +
																		' ' +
																		classes.userCaret +
																		' ' +
																		(preInvestorCollapse ? classes.caretActive : '')
																	}
																/>
															}
															disableTypography={true}
															className={collapseItemText}
														/>
													</NavLink>
													<Collapse in={preInvestorCollapse} unmountOnExit>
														<List className={classes.list + ' ' + classes.collapseList}>
															{/* Pre-Registrar Inversor */}
															<ListItem className={collapseItemText}>
																<NavLink
																	to={'/admin/pre-register'}
																	className={classes.itemLink + ' ' + classes.collapseItemLink}
																>
																	<span className={classes.collapseItemMini}>
																		<HowToReg />
																	</span>
																	<ListItemText
																		primary='Pre-Registrar Inversor'
																		disableTypography={true}
																		className={collapseItemText}
																	/>
																</NavLink>
															</ListItem>
															{/* Pre-Registros */}
															<ListItem className={collapseItemText}>
																<NavLink
																	to={'/admin/pre-investor-list'}
																	className={classes.itemLink + ' ' + classes.collapseItemLink}
																>
																	<span className={classes.collapseItemMini}>
																		<ListIcon />
																	</span>
																	<ListItemText
																		primary='Pre-Registros'
																		disableTypography={true}
																		className={collapseItemText}
																	/>
																</NavLink>
															</ListItem>
														</List>
													</Collapse>
												</ListItem>
											)}
										</List>
									</Collapse>
								</ListItem>
							)}
							{/* Prestamos a T. Fijo */}
							{(userIsAdmin || userInfo.isManagement || userInfo.isContabilidad || userInfo.isMarketing) && (
								<ListItem className={collapseItemText}>
									<NavLink to={'/admin/fixed-rate-loans'} className={classes.itemLink + ' ' + classes.collapseItemLink}>
										<span className={classes.collapseItemMini}>
											<ContentPasteGo />
										</span>
										<ListItemText
											primary='Prestamos a T. Fijo '
											disableTypography={true}
											className={collapseItemText}
										/>
									</NavLink>
								</ListItem>
							)}
						</List>
					</Collapse>
				</ListItem>
			)}
			{/* Estadísticas */}
			{(userIsAdmin || userInfo.isManagement || userInfo.isContabilidad || userInfo.isInvestorRelations) && (
				<ListItem className={classes.collapseItem}>
					<NavLink
						to={'#!'}
						className={classes.itemLink + ' ' + classes.collapseItemLink}
						onClick={(e) => {
							e.preventDefault()
							setStadisticCollapse(!stadisticCollapse)
						}}
					>
						<span className={classes.collapseItemMini}>
							<Assessment />
						</span>
						<ListItemText
							primary={'Estadísticas'}
							secondary={
								<b
									className={
										classes.caret + ' ' + classes.userCaret + ' ' + (stadisticCollapse ? classes.caretActive : '')
									}
								/>
							}
							disableTypography={true}
							className={collapseItemText}
						/>
					</NavLink>
					<Collapse in={stadisticCollapse} unmountOnExit>
						<List className={classes.list + ' ' + classes.collapseList}>
							<ListItem className={collapseItemText}>
								<NavLink to={'/admin/stadistics'} className={classes.itemLink + ' ' + classes.collapseItemLink}>
									<span className={classes.collapseItemMini}>
										<ListIcon />
									</span>
									<ListItemText
										primary='Inversiones por fecha e inversor'
										disableTypography={true}
										className={collapseItemText}
									/>
								</NavLink>
							</ListItem>
							<ListItem className={collapseItemText}>
								<NavLink to={'/admin/kpis'} className={classes.itemLink + ' ' + classes.collapseItemLink}>
									<span className={classes.collapseItemMini}>
										<Analytics />
									</span>
									<ListItemText primary='Kpis' disableTypography={true} className={collapseItemText} />
								</NavLink>
							</ListItem>
							<ListItem className={collapseItemText}>
								<NavLink to={'/admin/last-investments'} className={classes.itemLink + ' ' + classes.collapseItemLink}>
									<span className={classes.collapseItemMini}>
										<ContentPasteSearch />
									</span>
									<ListItemText
										primary='Perfiles última inversión'
										disableTypography={true}
										className={collapseItemText}
									/>
								</NavLink>
							</ListItem>
							{(userIsAdmin || userInfo.isMarketing || userInfo.isManagement) && (
								<ListItem className={collapseItemText}>
									<NavLink to={'/admin/delayed-projects'} className={classes.itemLink + ' ' + classes.collapseItemLink}>
										<span className={classes.collapseItemMini}>
											<Alarm />
										</span>
										<ListItemText
											primary='Proyectos Retrasados'
											disableTypography={true}
											className={collapseItemText}
										/>
									</NavLink>
								</ListItem>
							)}
							<ListItem className={collapseItemText}>
								<NavLink to={'/admin/statistics/login'} className={classes.itemLink + ' ' + classes.collapseItemLink}>
									<span className={classes.collapseItemMini}>
										<Login />
									</span>
									<ListItemText primary='Inicio de Sesión' disableTypography={true} className={collapseItemText} />
								</NavLink>
							</ListItem>
							<ListItem className={collapseItemText}>
								<NavLink to={'/admin/see-info'} className={classes.itemLink + ' ' + classes.collapseItemLink}>
									<span className={classes.collapseItemMini}>
										<Info />
									</span>
									<ListItemText primary='Ver información' disableTypography={true} className={collapseItemText} />
								</NavLink>
							</ListItem>
							{/* <ListItem className={collapseItemText}>
								<NavLink
									to={'/admin/statistics/project-info-viewed'}
									className={classes.itemLink + ' ' + classes.collapseItemLink}
								>
									<span className={classes.collapseItemMini}>
										<Preview />
									</span>
									<ListItemText primary='Ver Información' disableTypography={true} className={collapseItemText} />
								</NavLink>
							</ListItem> */}
						</List>
					</Collapse>
				</ListItem>
			)}
			{/* Administradores */}
			{(userIsAdmin || userInfo.isManagement) && (
				<ListItem className={classes.collapseItem}>
					<NavLink
						to={'#!'}
						className={classes.itemLink + ' ' + classes.collapseItemLink}
						onClick={(e) => {
							e.preventDefault()
							setAdminCollapse(!adminCollapse)
						}}
					>
						<span className={classes.collapseItemMini}>
							<SupervisedUserCircle />
						</span>
						<ListItemText
							primary={'Administradores'}
							secondary={
								<b
									className={classes.caret + ' ' + classes.userCaret + ' ' + (adminCollapse ? classes.caretActive : '')}
								/>
							}
							disableTypography={true}
							className={collapseItemText}
						/>
					</NavLink>
					<Collapse in={adminCollapse} unmountOnExit>
						<List className={classes.list + ' ' + classes.collapseList}>
							<ListItem className={collapseItemText}>
								<NavLink to={'/admin/register-user'} className={classes.itemLink + ' ' + classes.collapseItemLink}>
									<span className={classes.collapseItemMini}>
										<PersonAdd />
									</span>
									<ListItemText
										primary='Registro de Administradores'
										disableTypography={true}
										className={collapseItemText}
									/>
								</NavLink>
							</ListItem>
							<ListItem className={collapseItemText}>
								<NavLink to={'/admin/user-list'} className={classes.itemLink + ' ' + classes.collapseItemLink}>
									<span className={classes.collapseItemMini}>
										<ListIcon />
									</span>
									<ListItemText primary='Administradores' disableTypography={true} className={collapseItemText} />
								</NavLink>
							</ListItem>
						</List>
					</Collapse>
				</ListItem>
			)}
			{/* Propietarios */}
			{(userIsAdmin || userInfo.isManagement || userInfo.isInvestorRelations) && (
				<ListItem className={classes.collapseItem}>
					<NavLink
						to={'#!'}
						className={classes.itemLink + ' ' + classes.collapseItemLink}
						onClick={(e) => {
							e.preventDefault()
							setOwnersCollapse(!ownersCollapse)
						}}
					>
						<span className={classes.collapseItemMini}>
							<People />
						</span>
						<ListItemText
							primary={'Propietarios'}
							secondary={
								<b
									className={
										classes.caret + ' ' + classes.userCaret + ' ' + (ownersCollapse ? classes.caretActive : '')
									}
								/>
							}
							disableTypography={true}
							className={collapseItemText}
						/>
					</NavLink>
					<Collapse in={ownersCollapse} unmountOnExit>
						<List className={classes.list + ' ' + classes.collapseList}>
							<ListItem className={collapseItemText}>
								<NavLink
									to={'/admin/owners-register-user'}
									className={classes.itemLink + ' ' + classes.collapseItemLink}
								>
									<span className={classes.collapseItemMini}>
										<PersonAdd />
									</span>
									<ListItemText
										primary='Registrar Propietarios'
										disableTypography={true}
										className={collapseItemText}
									/>
								</NavLink>
							</ListItem>
						</List>
						<List className={classes.list + ' ' + classes.collapseList}>
							<ListItem className={collapseItemText}>
								<NavLink to={'/admin/owners-list-user'} className={classes.itemLink + ' ' + classes.collapseItemLink}>
									<span className={classes.collapseItemMini}>
										<ListIcon />
									</span>
									<ListItemText
										primary='Listado de Propietarios'
										disableTypography={true}
										className={collapseItemText}
									/>
								</NavLink>
							</ListItem>
						</List>
					</Collapse>
				</ListItem>
			)}
			{/* Gestionar Notificaciones */}
			{userIsAdmin && (
				<ListItem className={cx({ [classes.item]: false }, { [classes.collapseItem]: true })}>
					<NavLink
						to={'#!'}
						className={classes.itemLink + ' ' + classes.collapseItemLink}
						onClick={(e) => {
							e.preventDefault()
							setNotificationCollapse(!notificationCollapse)
						}}
					>
						<span className={classes.collapseItemMini}>
							<Notifications />
						</span>
						<ListItemText
							primary={'Gestionar Notificaciones'}
							secondary={
								<b
									className={
										classes.caret + ' ' + classes.userCaret + ' ' + (notificationCollapse ? classes.caretActive : '')
									}
								/>
							}
							disableTypography={true}
							className={collapseItemText}
						/>
					</NavLink>
					<Collapse in={notificationCollapse} unmountOnExit>
						<List className={classes.list + ' ' + classes.collapseList}>
							{/* Cambios de Fecha Fin */}
							<ListItem className={collapseItemText}>
								<NavLink
									to={'/admin/notification-delayed-list'}
									className={classes.itemLink + ' ' + classes.collapseItemLink}
								>
									<span className={classes.collapseItemMini}>
										<ListIcon />
									</span>
									<ListItemText primary='Cambios de Fecha Fin' disableTypography={true} className={collapseItemText} />
								</NavLink>
							</ListItem>
							{/* Enviar notificación */}
							<ListItem className={collapseItemText}>
								<NavLink to={'/admin/new-notification'} className={classes.itemLink + ' ' + classes.collapseItemLink}>
									<span className={classes.collapseItemMini}>
										<NotificationAdd />
									</span>
									<ListItemText primary='Enviar notificación' disableTypography={true} className={collapseItemText} />
								</NavLink>
							</ListItem>
						</List>
					</Collapse>
				</ListItem>
			)}
			{/* Historial de Actividades */}
			{userIsAdmin && (
				<ListItem className={cx({ [classes.item]: false }, { [classes.collapseItem]: true })}>
					<NavLink to={'/admin/activities'} className={collapseItemLink}>
						<Icon className={classes.itemIcon}>{<Report />}</Icon>
						<ListItemText
							primary={'Historial de Actividades'}
							disableTypography={true}
							className={cx({ [itemText]: true }, { [collapseItemText]: false })}
						/>
					</NavLink>
				</ListItem>
			)}
			{/* Desarrolladores */}
			{userInfo.isSupport && (
				<ListItem className={cx({ [classes.item]: false }, { [classes.collapseItem]: true })}>
					<NavLink
						to={'#!'}
						className={classes.itemLink + ' ' + classes.collapseItemLink}
						onClick={(e) => {
							e.preventDefault()
							setDevelopersCollapse(!developersCollapse)
						}}
					>
						<span className={classes.collapseItemMini}>
							<Source />
						</span>
						<ListItemText
							primary={'Desarrolladores'}
							secondary={
								<b
									className={
										classes.caret + ' ' + classes.userCaret + ' ' + (developersCollapse ? classes.caretActive : '')
									}
								/>
							}
							disableTypography={true}
							className={collapseItemText}
						/>
					</NavLink>
					<Collapse in={developersCollapse} unmountOnExit>
						<List className={classes.list + ' ' + classes.collapseList}>
							{/* Liquidación de Proyecto */}
							<ListItem className={collapseItemText}>
								<NavLink to={'/admin/developers/project-settlement'} className={collapseItemLink}>
									<Icon className={classes.itemIcon}>{<DeveloperBoard />}</Icon>
									<ListItemText
										primary={'Liquidación de Proyecto'}
										disableTypography={true}
										className={cx({ [itemText]: true }, { [collapseItemText]: false })}
									/>
								</NavLink>
							</ListItem>
						</List>
					</Collapse>
				</ListItem>
			)}
			<LogoutOption itemText={itemText} collapseItemText={collapseItemText} />
		</List>
	)
}

export default SidebarOptions

// Sidebar Options BK
{
	/* Administrar / Proyectos
		const [projectCollapse, setProjectCollapse] = useState(false)
		<ListItem className={collapseItemText}>
			<NavLink
				to={'#!'}
				className={classes.itemLink + ' ' + classes.collapseItemLink}
				onClick={(e) => {
					e.preventDefault()
					setProjectCollapse(!projectCollapse)
				}}
			>
				<span className={classes.collapseItemMini}>
					<HomeWork />
				</span>
				<ListItemText
					primary={'Proyectos'}
					secondary={
						<b
							className={
								classes.caret +
								' ' +
								classes.userCaret +
								' ' +
								(projectCollapse ? classes.caretActive : '')
							}
						/>
					}
					disableTypography={true}
					className={collapseItemText}
				/>
			</NavLink>
			<Collapse in={projectCollapse} unmountOnExit>
				<List className={classes.list + ' ' + classes.collapseList}>
					Añadir Proyecto
					{(userIsAdmin || userInfo.isMarketing || userInfo.isManagement) && (
						<ListItem className={collapseItemText}>
							<NavLink
								to={'/admin/register-project'}
								className={classes.itemLink + ' ' + classes.collapseItemLink}
							>
								<span className={classes.collapseItemMini}>
									<AddBusiness />
								</span>
								<ListItemText
									primary='Añadir Proyecto'
									disableTypography={true}
									className={collapseItemText}
								/>
							</NavLink>
						</ListItem>
					)}
				</List>
			</Collapse>
		</ListItem> 
	*/
	/* Backups
		<ListItem className={cx({ [classes.item]: false }, { [classes.collapseItem]: true })}>
			<NavLink to={'/admin/backups'} className={collapseItemLink}>
				<Icon className={classes.itemIcon}>{<CloudDownload />}</Icon>
				<ListItemText
					primary={'Backups'}
					disableTypography={true}
					className={cx({ [itemText]: true }, { [collapseItemText]: false })}
				/>
			</NavLink>
		</ListItem> 
	*/
}
